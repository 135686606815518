<template>
    <!-- 病歷內通話 -->
    <vs-button
        :id="'button-with-loading' + componentId"
        :class="propClass"
        :style="getStyle()"
        :type="type"
        :color="color"
        icon-pack="feather"
        :icon="icon"
        :disabled="disabled"
        @click.stop="openLoadingContained"
    >
        {{ btnName ? btnName : $t('components.call.call') }}
    </vs-button>
</template>

<script>
import { saveQueueANDMR, getMidByPid, getCallType, call_p2p } from '@/api/user'
export default {
    props: {
        propClass: {
            type: String,
            default: 'vs-con-loading__container cd-button-sm-2w-icon',
            required: false,
        },
        type: {
            type: String,
            default: 'filled',
            required: false,
        },
        color: {
            type: String,
            default: 'primary',
            required: false,
        },
        icon: {
            type: String,
            default: 'icon-video',
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        btnName: {
            type: String,
            required: false,
        },
        selectedPid: { required: true },
        selectedPname: { required: true },
        selectdid: { required: false },
        qid: { required: false },
        interval: { required: false },
        mrid: { required: false },
        finish: { required: false },
        callPopup: { required: false },
    },
    computed: {
        device() {
            return this.$store.getters.device
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        contacts() {
            return this.$store.getters.contactsall
        },
        partnerid() {
            return this.$store.getters.partner
        },
        calling() {
            return this.$store.getters.calling
        },
        componentId() {
            if (this.qid) {
                return this.qid
            } else {
                return this.selectedPid
            }
        },
        doctortype() {
            return this.$store.state.doctortype
        },
        mid() {
            return this.$store.state.mid
        },
        isDev() {
            return this.$store.getters.isDev
        },
        dmid() {
            return this.$store.state.mid
        },
    },
    watch: {
        selectdid(n) {
            if (this.selectdid != 'patient' && this.selectdid != '') {
                this.selectdname = _.filter(this.contacts, [
                    'to_did',
                    n,
                ])[0].fullname
            } else {
                this.selectdname = ''
            }
        },
    },
    data() {
        return {
            message: '',
            phone_status: 0,
            // 通話選擇是否開啟新分頁視窗
            popupCall: false,
            // 通話是否打給代理人
            isAgent: false,
            // 通話資料
            callPayload: [],
            // 今天日期
            currentday: this.$moment(new Date(), 'YYYY-MM-DD').format(
                'YYYY-MM-DD'
            ),
            // 轉接醫師姓名
            selectdname: '',
        }
    },
    methods: {
        // 通話 加上loading防呆 & 檢查網頁版裝置 然後打給病人 callOut
        openLoadingContained() {
            //判斷通話模式為p2p或mcu
            getCallType(this.partnerid).then((res) => {
                if (res.data.is_p2p == true) {
                    getMidByPid(this.selectedPid).then((res) => {
                        let pmid = res.data.data.mid
                        if (this.selectdid == 'patient' || !this.selectdid) {
                            this.call_p2p(0, pmid, '')
                        } else {
                            getMidByDid(this.selectdid).then((res) => {
                                this.call_p2p(1, pmid, res.data.data.mid)
                            })
                        }
                    })
                } else {
                    if (this.callPopup) {
                        this.$emit('closeCallPopup')
                    }
                    this.phone_status = 1
                    this.$vs.loading({
                        background: 'primary',
                        color: '#fff',
                        container: '#button-with-loading' + this.componentId,
                        scale: 0.45,
                    })
                    if (this.phone_status == 1) {
                        // 網頁版 先確認是否允許開啟鏡頭&麥克風
                        if (this.device == 3) {
                            navigator.mediaDevices
                                .getUserMedia({ video: true, audio: true })
                                .then((stream) => {
                                    this.selectdid == 'patient' ||
                                    !this.selectdid
                                        ? this.callOut()
                                        : this.callAgent()
                                })
                                .catch((error) => {
                                    console.log('error', error)
                                    alert(this.$t('components.call.useMic'))
                                })
                        } else {
                            this.selectdid == 'patient' || !this.selectdid
                                ? this.callOut()
                                : this.callAgent()
                        }
                    }
                    setTimeout(() => {
                        this.$vs.loading.close(
                            '#button-with-loading' +
                                this.componentId +
                                ' > .con-vs-loading'
                        )
                        this.phone_status = 0
                    }, 3000)
                }
            })
        },
        // 通話
        async callOut() {
            var _self = this
            var video = 'true'
            //判斷中斷重播要在call_info加入標記
            if (_self.mrid) {
                let payload_call_info = {
                    queue_id: _self.qid,
                    test_flag: 1,
                    mr_id: _self.mrid,
                }
                _self.$store.dispatch('updatecallinfostatus', payload_call_info)
            }
            let payload = {
                queue_id: _self.qid ? _self.qid : 0,
                calltype: 'video',
                from: _self.device,
                spid: _self.selectedPid,
                partnerid: _self.partnerid,
            }
            await _self.$store
                .dispatch('callOut', payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        // 發送建立房間socket給掛號醫師,授權醫事人員
                        // sendCreateRoom({"qid": res.data.qid})
                        let domain = window.location.host
                        let testparam =
                            domain == 'his-dev.curdoctor.com.tw' ||
                            domain == '127.0.0.1:8080'
                                ? '&test=true'
                                : ''
                        var videopath =
                            'https://mcu.curdoctor.com.tw:3004/?f=true&v=true' +
                            (window.location.hostname.indexOf('his-dev') > -1
                                ? '&test=true'
                                : '') +
                            '&scheme_time=' +
                            (res.data.scheme_time == ''
                                ? 0
                                : res.data.scheme_time) +
                            testparam +
                            '&room=' +
                            res.data.roomid +
                            '&queue_id=' +
                            res.data.qid +
                            '&token=' +
                            localStorage.getItem('doc_access_token') +
                            '&m=' +
                            _self.doctortype +
                            '-' +
                            _self.mid
                        _self.$store.dispatch('setMR', {
                            mrid: _self.mrid ? _self.mrid : undefined,
                            qid: res.data.qid,
                        })
                        _self.$store.commit('SET_QUEUE_ID', res.data.qid)
                        _self.$store.commit('SET_CALLING', true)
                        _self.$store.dispatch('setRoomId', res.data.roomid)
                        _self.$store.dispatch('updateDoctorStatus', 2000)
                        _self.$socket.client.emit('join_room', {
                            room: res.data.roomid,
                        })
                        _self.$store.commit('SET_ACTIVE_PATIENT_INFO', {
                            name: _self.selectedPname,
                            pid: _self.selectedPid,
                        })
                        // localStorage.setItem('selectedpid', JSON.stringify({'name': _self.selectedPname, 'pid': _self.selectedPid}))
                        // localStorage.setItem('calling', JSON.stringify({'qid': res.data.qid, 'pid': _self.selectedPid, 'roomId': res.data.roomid, 'mrid': '', 'videopath': videopath}))
                        // _self.$store.dispatch('setVideoPath', '')
                        _self.$store.dispatch('setVideoPath', videopath)
                        _self.$store.dispatch('toggleRightPanel', false)
                        // 網頁
                        if (_self.device == 3) {
                            _self.$store.dispatch('toggleRightPanel', true)
                        }
                        // android
                        else if (_self.device == 2) {
                            var jsonStr = JSON.stringify({
                                roomNumber: res.data.roomid,
                                queueID: res.data.qid.toString(),
                                doctorName: res.data.dname,
                                did: res.data.did,
                                videoCall: video,
                                scheme_time:
                                    res.data.scheme_time == ''
                                        ? 0
                                        : res.data.scheme_time,
                            })
                            window.android.callRTC(jsonStr)
                        }
                        // ios
                        else if (_self.device == 1) {
                            var jsonStr = JSON.stringify({
                                roomNumber: res.data.roomid,
                                queueID: res.data.qid.toString(),
                                patientName: res.data.dname,
                                videoCall: video,
                                photo: '',
                                scheme_time:
                                    res.data.scheme_time == ''
                                        ? 0
                                        : res.data.scheme_time,
                            })
                            window.webkit.messageHandlers.CallPatient.postMessage(
                                jsonStr
                            )
                        }
                        if (
                            _self.$route.path !=
                            '/emr2/' + _self.selectedPid + '/' + res.data.qid
                        ) {
                            _self.$router.push(
                                '/emr2/' +
                                    _self.selectedPid +
                                    '/' +
                                    res.data.qid
                            )
                        }
                    } else if (res.data.status == 'ERROR') {
                        _self.$vs.notify({
                            color: 'danger',
                            title: 'ERROR',
                            text: res.data.message,
                        })
                    }
                })
                .catch((e) => {
                    console.log('dispatch callOut 錯誤', e)
                })
        },
        // 通話 聯繫代理人
        async callAgent() {
            var _self = this
            var video = 'true'
            let payload = {
                devicetype: _self.device,
                spid: _self.selectedPid,
                pname: _self.selectedPname,
                todid: _self.selectdid,
                dname: _self.selectdname,
                date: _self.currentday,
                interval: _self.interval,
                partnerid: _self.partnerid,
            }
            if (_self.qid) {
                payload.qid = _self.qid
            }
            await saveQueueANDMR(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        // 發送建立房間socket給掛號醫師,授權醫事人員
                        // sendCreateRoom({"qid": res.data.qid})
                        // let domain = window.location.host
                        // let testparam = (domain=='his-dev.curdoctor.com.tw' || domain=='127.0.0.1:8080') ? '&test=true' : ''
                        // var videopath = 'https://mcu.curdoctor.com.tw:3004/?f=true&v=true'+(window.location.hostname.indexOf('his-dev')>-1?'&test=true':'')+'&scheme_time='+((res.data.scheme_time=='')?0:res.data.scheme_time)+testparam+'&room='+res.data.roomId+'&queue_id='+res.data.qid+'&token='+localStorage.getItem('doc_access_token')+'&m='+_self.doctortype+'-'+_self.mid;
                        // console.log("聯繫代理人IFRAME",videopath)
                        _self.$store.commit('SET_CALLING', true)
                        _self.$store.dispatch('setRoomId', res.data.roomId)
                        _self.$store.dispatch('updateDoctorStatus', 2000)
                        _self.$socket.client.emit('join_room', {
                            room: res.data.roomId,
                        })
                        _self.$store.commit('SET_ACTIVE_PATIENT_INFO', {
                            name: _self.selectedPname,
                            pid: _self.selectedPid,
                        })
                        // localStorage.setItem('selectedpid', JSON.stringify({'name': _self.selectedPname, 'pid': _self.selectedPid}))
                        // _self.$store.dispatch('setVideoPath', '')
                        _self.$store.dispatch('toggleRightPanel', false)
                        if (_self.device == 3) {
                            var videopath =
                                'https://mcu.curdoctor.com.tw:3004/?f=true&v=true'
                            videopath += this.isDev == true ? '&test=true' : ''
                            videopath +=
                                res.data.scheme_time == ''
                                    ? 0
                                    : '&scheme_time=' + res.data.scheme_time
                            videopath += '&room=' + res.data.roomId
                            videopath += '&queue_id=' + res.data.qid
                            videopath +=
                                '&token=' +
                                localStorage.getItem('doc_access_token')
                            videopath +=
                                '&m=' + this.doctortype + '-' + this.mid
                            _self.$store.dispatch('setVideoPath', videopath)
                            _self.$store.dispatch('toggleRightPanel', true)
                        } else if (_self.device == 2) {
                            var jsonStr = JSON.stringify({
                                roomNumber: res.data.roomId,
                                queueID: res.data.qid.toString(),
                                doctorName: _self.selectdname,
                                did: _self.selectdid,
                                videoCall: video,
                                scheme_time: res.data.scheme_time,
                            })
                            window.android.callRTC(jsonStr)
                        } else if (_self.device == 1) {
                            var data = JSON.stringify({
                                roomNumber: res.data.roomId,
                                queueID: res.data.qid.toString(),
                                patientName: _self.selectdname,
                                videoCall: video,
                                scheme_time: res.data.scheme_time,
                            })
                            window.webkit.messageHandlers.CallPatient.postMessage(
                                data
                            )
                        }
                        if (
                            _self.$route.path !=
                            '/emr2/' + _self.selectedPid + '/' + res.data.qid
                        ) {
                            _self.$router.push({
                                path:
                                    '/emr2/' +
                                    _self.selectedPid +
                                    '/' +
                                    res.data.qid,
                                query: {
                                    proxy_call_did: _self.selectdid,
                                    proxy_call_name: _self.selectdname,
                                    interval: _self.interval,
                                },
                            })
                        }
                    } else if (res.data.status == 'ERROR') {
                        if (res.data.message == '病人未上線') {
                            _self.$vs.dialog({
                                color: 'warning',
                                title: _self.$t('popup.alert'),
                                text: _self.$t(
                                    'components.call.patientOffline'
                                ),
                                acceptText: _self.$t('popup.confirm'),
                            })
                        } else {
                            _self.$vs.dialog({
                                color: 'warning',
                                title: _self.$t('popup.alert'),
                                text: _self.$t('components.call.doctorOffline'),
                                acceptText: _self.$t('popup.confirm'),
                            })
                        }
                    }
                })
                .catch((err) => {
                    console.log('err call out save mr and queue:: ', err)
                })
        },
        // p2p通話 is_doctor 0=民眾 1=醫事人員
        async call_p2p(is_doctor, pmid, dmid = 0) {
            let route = _.replace(
                _.lowerCase(_.split(this.$route.fullPath, '/', 2)[1]),
                ' ',
                ''
            )
            var payload = {
                partner_id: this.partnerid,
                is_doctor: is_doctor,
                to_mid: pmid,
                src: route,
                queue_id: this.qid,
            }

            if (dmid > 0) {
                payload['doc_mid'] = dmid
                var mid = dmid
            } else {
                var mid = pmid
            }

            if (this.snParams) payload.sn_id = this.snParams.id
            await call_p2p(payload).then((res) => {
                if (res.data.status == 'OK') {
                    // $('#p2p').show();
                    this.$store.commit('SET_CALL_ID', res.data.callId)
                    this.$store.commit(
                        'SET_LOCAL_ID',
                        'curdr_' + this.partnerid + '_' + this.dmid + '_1'
                    )
                    this.$store.commit(
                        'SET_REMOTE_ID',
                        'curdr_' + this.partnerid + '_' + mid + '_' + is_doctor
                    )
                    this.$store.commit('SET_CALLING', true)
                    this.$store.commit('SET_ROOM_ID', res.data.room_Id)
                    this.$store.commit('SET_QUEUE_ID', res.data.queue_id)
                    remoteID = res.data.remote_Id
                    console.log(
                        'call.vue call_p2p function remoteID => ',
                        remoteID
                    )
                    this.$store.dispatch('updateDoctorStatus', 2000)
                    p2p_call(
                        true,
                        res.data.room_Id,
                        res.data.callId,
                        res.data.remote_Id,
                        false,
                        res.data.caller,
                        true,
                        this.UUID
                    )
                    localStorage.setItem('p2p_queueid', res.data.queue_id)
                    localStorage.setItem('p2p_tomid', this.dmid)
                    let send_data = {
                        queue_id: res.data.queue_id,
                    }
                    p2pCallData = send_data
                    this.$emit('closePopup')
                    if (
                        this.$router.currentRoute.fullPath !=
                        '/emr2/' + this.selectedPid + '/' + res.data.queue_id
                    ) {
                        this.$router.push(
                            '/emr2/' +
                                this.selectedPid +
                                '/' +
                                res.data.queue_id
                        )
                    }
                } else {
                    if (is_doctor == 0) {
                        this.$vs.notify({
                            color: 'danger',
                            title: 'ERROR',
                            text: '民眾未上線',
                        })
                    } else {
                        this.$vs.notify({
                            color: 'danger',
                            title: 'ERROR',
                            text: '醫師未上線',
                        })
                    }
                    console.log('call ERROR: ' + res.data.msg)
                }
            })
        },
        // 取邊框顏色
        getStyle() {
            if (this.type != 'filled') return ''
            if (this.color == 'danger') {
                return 'border: 1px #e7441c solid;'
            } else if (this.color == 'success') {
                return 'border: 1px #104f2c solid;'
            } else if (this.color == 'primary') {
                return 'border: 1px #003f8e solid;'
            }
        },
    },
}
</script>
